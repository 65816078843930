var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { dark: "" } },
    [
      _vm.error.statusCode === 404
        ? _c("h1", [_vm._v("\n    " + _vm._s(_vm.pageNotFound) + "\n  ")])
        : _c("h1", [_vm._v("\n    " + _vm._s(_vm.otherError) + "\n  ")]),
      _vm._v(" "),
      _c("NuxtLink", { attrs: { to: "/" } }, [_vm._v("\n    Home page\n  ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }