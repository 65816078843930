var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { dark: "" } },
    [
      _c("Header"),
      _vm._v(" "),
      _c("v-main", { staticClass: "bg-black" }, [_c("div", [_c("Nuxt")], 1)]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }