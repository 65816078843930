var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-black" }, [
    _c("div", { staticClass: "container-header-itens" }, [
      _c("div", { staticClass: "container-menu" }, [
        _c(
          "svg",
          {
            attrs: {
              fill: "#000000",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 24 24",
            },
            on: { click: _vm.openMobileMenu },
          },
          [
            _c("path", {
              attrs: {
                d: "M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "header-midle-block" }, [
        _c("a", { attrs: { href: "https://www.digitalhorizon.sv/" } }, [
          _vm._v(" " + _vm._s(_vm.$t("header.link_1")) + " "),
        ]),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "https://www.digitalhorizon.sv/#comp-kr24089t" } },
          [_vm._v(" " + _vm._s(_vm.$t("header.link_2")) + " ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "https://www.digitalhorizon.sv/#comp-kr24egkd" } },
          [_vm._v(" " + _vm._s(_vm.$t("header.link_3")) + " ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "https://www.digitalhorizon.sv/#comp-kr24egkd" } },
          [_vm._v(" " + _vm._s(_vm.$t("header.link_4")) + " ")]
        ),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "https://www.digitalhorizon.sv/#comp-kr24egja" } },
          [_vm._v(" " + _vm._s(_vm.$t("header.link_5")) + " ")]
        ),
        _vm._v(" "),
        _c("button", { staticClass: "pink-default-button" }, [
          _vm._v(_vm._s(_vm.$t("header.button_link_name"))),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.mobileMenuState
      ? _c("div", { staticClass: "container-mobile-menu" }, [
          _c("div", { staticClass: "container-close" }, [
            _c("p", { on: { click: _vm.colseMobileModal } }, [_vm._v("x")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container-body-mobile-menu" }, [
            _c("a", { attrs: { href: "https://www.digitalhorizon.sv/" } }, [
              _vm._v(" " + _vm._s(_vm.$t("header.link_1")) + " "),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "https://www.digitalhorizon.sv/#comp-kr24089t" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("header.link_2")) + " ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "https://www.digitalhorizon.sv/#comp-kr24egkd" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("header.link_3")) + " ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "https://www.digitalhorizon.sv/#comp-kr24egkd" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("header.link_4")) + " ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "https://www.digitalhorizon.sv/#comp-kr24egja" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("header.link_5")) + " ")]
            ),
            _vm._v(" "),
            _c("button", { staticClass: "pink-default-button" }, [
              _vm._v(_vm._s(_vm.$t("header.button_link_name"))),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-first-block" }, [
      _c("img", {
        staticClass: "img-header",
        attrs: {
          src: require("assets/dh_white.png"),
          alt: "digital horizon logo",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }