<template>
  <div class="container-first-itens">


    <div class="container-first-section-text">
      <h1>NFT Collection</h1>
      <h3>Unique Design. Limited Supply. Lifetime Rewards.</h3>
    </div>

    <div class="container-first-section-texts">
      <p>The Digital Horizon collection is the first of its kind, offering an NFT stake distribution system fully backed by present and future operations of a Latin-American company. Digital Horizon NFTs will take advantage of the security and privacy technology of Solana’s Blockchain to make the collection more than mere pieces of art, but also to provide passive income for their Holders. </p>
      <p class="description-2">We truly believe that in the same way Bitcoin has revolutionized the monetary system, this particular NFT stake distribution model will set a disruptive model in the way companies are funded; much faster and more transparent than old capitalization models.</p>

      <div class="container-default-button">
        <a href="/NFT_WHITEPAPER_23_MAY_2023.pdf" target="_blank" class="mr-4">
          <button class="pink-default-button">
            <p class="m0">Whitepaper</p>
          </button>
        </a>

        <a href="https://youtu.be/IBiHcDeQSHo" target="_blank" class="text-decoration-none">
          <button class="pink-default-button custom-tutorial-button">
            <p>Tutorial</p>
            <div class="d-flex" v-html="iconSets.youtube"></div>
          </button>
        </a>
      </div>
      <div class="container-image-first-section">
        <img src="~/assets/dh-nft.png" alt="nft-imagem">
      </div>
    </div>


    <div class="custom-middle-container-cards">
      <div v-for="(i, index) in chosen" :key="index" class="container-nftChosens">
        <div :class="`container-texts-${i.cardType}`" class="left-container-nft">
          <div>
            <h1>{{i.nftTitle}}</h1>
            <p v-html="i.text"></p>
            <button @click="setChosenNft(i)" class="buy-nft-button" :class="`buy-nft-button-${i.cardType}`">BUY NOW</button>
          </div>
        </div>

        <div class="right-container-nft">
          <img :src="require(`~/assets/gifs/${i.cardType}_nft.gif`)" alt="this slowpoke moves" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data: () => ({
    chosen: [
      {
        id: 1,
        nftTitle: 'Blue Single NFT',
        cardType: 'blue',
        unitValue: '1x1',
        subtotal: '$2,500',
        fee: '100 USDT',
        text: `The Blue Digital Horizon NFT is the base-level NFT, representing 1 (one) single unit out of the maximum 5,000 total unit supply. <br> <br> The Blue Digital Horizon NFT is a Blue Card featuring a triple blue neon frame and a rotating loop, with the Digital Horizon icon in the front and a “digital” sunset on the back. The NFT also displays its name and value: BLUE SINGLE 1x1.`
      },
      {
        id: 2,
        nftTitle: 'Silver Compound NFT',
        cardType: 'silver',
        unitValue: '1x10',
        subtotal: '$25,000 - 3% =  $24,250',
        fee: '400 USDT',
        text: `The Silver Digital Horizon NFT is the mid-level NFT, representing 10 (ten) single units out of the maximum 5,000 total unit supply. <br> <br> The Silver Digital Horizon NFT is a Silver Card featuring a triple silver neon frame and a rotating loop, with the Digital Horizon icon in the front and a “digital” sunset on the back. The NFT also displays its name and value: SILVER COMPOUND 1x10.`
      },
      {
        id: 3,
        nftTitle: 'Gold Master NFT',
        cardType: 'gold',
        unitValue: '1x100',
        subtotal: '$250,000 - 5% =  $237,000',
        fee: '600 USDT',
        text: `The Gold Digital Horizon NFT is the highest-level NFT, representing 100 (one hundred) single units out of the maximum 5,000 total unit supply. <br> <br> The Gold Digital Horizon NFT is a Gold Card featuring a triple gold neon frame and a rotating loop, with the Digital Horizon icon in the front and a “digital” sunset on the back. The NFT also displays its name and value: GOLDEN MASTER 1x100.`
      },
    ]
  }),
  methods: {
    setChosenNft(nftChosen) {
      this.$emit('emitChosenNftOption', nftChosen.id)
      window.location.href = '#form-section'
    }
  }
}
</script>

<style lang="scss">
.container-nftChosens {
  display: flex;
  margin: 30px auto;
  max-width: 95%;
  justify-content: center;

  &:nth-child(2n) {
    flex-direction: row-reverse;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;

    &:nth-child(2n) {
      flex-direction: column-reverse;
    }
  }
}

.left-container-nft {
  text-align: start;
  align-items: center;
  display: flex;
  width: 45%;

  @media screen and (max-width: 1000px) {
    width: 90%;
    text-align: center !important;
    display: block;
    margin: 10px auto;

    button {
      margin: 70px auto;
    }
  }

  h1 {
    text-transform: uppercase;
    font-size: 2.7rem;
    font-weight: 100;
    margin-bottom: 50px;
  }
}

.buy-nft-button {
  font-size: 1.2rem;
  margin: 10px 0;
  display: block;
  width: 200px;
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 100px;
  box-shadow: 5px 3px 20px rgba(255, 255, 255, 0.533);
}

.right-container-nft {
  max-width: 100%;
  width: 70%;

  @media screen and (max-width: 1000px) {
    width: 90%;
    display: block;
    margin: 10px auto;
  }

  img {
    width: 100%;
  }
}

.description-2 {
  margin-top: 30px;
}

.pink-default-button {
  background-color: #dd27b6;
  padding: 10px 20px;
  border-radius: 4px;
  transition: 0.3s;

  &:hover {
    color: black;
    background-color: #fff;
  }
}



.buy-nft-button-blue {
  margin-top: 10vw;
  background-color: #0047ab;
}

.buy-nft-button-silver {
  background-color: #b3b3b3;
  margin: 5vw 0 0 auto;
}

.buy-nft-button-gold {
  background-color: #edb60b;
  margin: 5vw 0 0 0;
}

.container-first-itens {
  max-width: 95%;
  width: 70%;
  margin: 10px auto;

  @media screen and (max-width: 1500px) {
    width: 85%;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.container-default-button {
  display: flex;
  max-width: 100%;
  text-align: center;
  justify-content: center;
  width: 100%;

  a {
    color: #fff !important;
  }
}

.container-image-first-section {
  max-width: 100%;
  text-align: center;
  width: 100%;

  img {
    margin: 80px auto;
  }

  @media screen and (max-width: 1000px) {
    img {
      width: 80%;
    }
  }

  @media screen and (max-width: 800px) {
    img {
      width: 70%;
    }
  }

  @media screen and (max-width: 700px) {
    img {
      width: 80%;
    }
  }

}

/* .container-texts-blue {
  font-size: 2rem;
} */

.container-texts-silver {
  text-align: right;
}

.container-first-section-text {
  text-align: center;
  max-width: 95%;
  width: 75%;
  margin: 10px auto;

  h1 {
    color: #dd27b6;
    font-size: 4.2rem;
    font-weight: 100;
  }

  h3 {
    font-weight: 100;
    font-size: 1.5rem;
    margin: 5px 0px 25px 0px;
  }

  @media screen and (max-width: 1000px) {
    h1 {
      font-size: 3rem;
      font-weight: 100;
    }
  }

}

.custom-tutorial-button {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }

  svg {
    width: 30px;
    margin-left: 15px;
  }
}

.container-first-section-texts {
  max-width: 95%;
  width: 80%;
  margin: 10px auto;

  @media screen and (max-width: 1000px) {
    width: 95%;
  }
}

.custom-middle-container-cards {
  margin-left: 110px;

  @media screen and (max-width: 1500px) {
    margin-left: 45px;
  }

  @media screen and (max-width: 1000px) {
    margin-left: 0px;
  }

}

</style>
