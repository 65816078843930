var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "title-card-preview" }, [
      _vm._v(_vm._s(_vm.savedAmountsInfos.cardTypeTitle)),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Please review the information carefully. You will receive an email confirming your order, so make sure the information provided is correct."
      ),
    ]),
    _vm._v(" "),
    _c("div", [
      _vm._m(0),
      _vm._v(" "),
      _c("p", [
        _c("strong", [_vm._v("Subtotal:")]),
        _vm._v(" " + _vm._s(_vm.savedAmountsInfos.subTotal) + " "),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("strong", [_vm._v("Fee:")]),
        _vm._v(" " + _vm._s(_vm.savedAmountsInfos.fee) + " "),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("strong", [_vm._v("Total:")]),
        _vm._v(" " + _vm._s(_vm.savedAmountsInfos.total) + " "),
      ]),
    ]),
    _vm._v(" "),
    _c("div", [
      _c("p", [
        _c("strong", [_vm._v("Name:")]),
        _vm._v(" " + _vm._s(_vm.step1InfoPreview.name) + " "),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("strong", [_vm._v("Email:")]),
        _vm._v(" " + _vm._s(_vm.step1InfoPreview.email) + " "),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("strong", [_vm._v("My wallet:")]),
        _vm._v(" " + _vm._s(_vm.step1InfoPreview.walletAddress) + " "),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container-buttons-step2", attrs: { align: "center" } },
      [
        _c(
          "v-btn",
          {
            staticClass: "previus-button-step2",
            attrs: { color: "primary" },
            on: { click: _vm.returnStep },
          },
          [_vm._v("\n        PREVIOUS\n      ")]
        ),
        _vm._v(" "),
        _c(
          "v-btn",
          {
            staticClass: "continue-button-step2",
            attrs: { text: "" },
            on: {
              click: function ($event) {
                return _vm.submitAllInfo()
              },
            },
          },
          [_vm._v("\n        SUBMIT\n      ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("Quantity:")]), _vm._v(" 1 ")])
  },
]
render._withStripped = true

export { render, staticRenderFns }