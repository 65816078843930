import svg_set from "~/assets/icons/svg_set"

export default {
  data: () => ({
    globalMessage: 'Global Mixing',
    iconSets: {
      ...svg_set
    }
  })
}
