var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-first-itens" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "container-first-section-texts" }, [
      _c("p", [
        _vm._v(
          "The Digital Horizon collection is the first of its kind, offering an NFT stake distribution system fully backed by present and future operations of a Latin-American company. Digital Horizon NFTs will take advantage of the security and privacy technology of Solana’s Blockchain to make the collection more than mere pieces of art, but also to provide passive income for their Holders. "
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "description-2" }, [
        _vm._v(
          "We truly believe that in the same way Bitcoin has revolutionized the monetary system, this particular NFT stake distribution model will set a disruptive model in the way companies are funded; much faster and more transparent than old capitalization models."
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container-default-button" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "text-decoration-none",
            attrs: { href: "https://youtu.be/IBiHcDeQSHo", target: "_blank" },
          },
          [
            _c(
              "button",
              { staticClass: "pink-default-button custom-tutorial-button" },
              [
                _c("p", [_vm._v("Tutorial")]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "d-flex",
                  domProps: { innerHTML: _vm._s(_vm.iconSets.youtube) },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._m(2),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "custom-middle-container-cards" },
      _vm._l(_vm.chosen, function (i, index) {
        return _c("div", { key: index, staticClass: "container-nftChosens" }, [
          _c(
            "div",
            {
              staticClass: "left-container-nft",
              class: "container-texts-" + i.cardType,
            },
            [
              _c("div", [
                _c("h1", [_vm._v(_vm._s(i.nftTitle))]),
                _vm._v(" "),
                _c("p", { domProps: { innerHTML: _vm._s(i.text) } }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "buy-nft-button",
                    class: "buy-nft-button-" + i.cardType,
                    on: {
                      click: function ($event) {
                        return _vm.setChosenNft(i)
                      },
                    },
                  },
                  [_vm._v("BUY NOW")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "right-container-nft" }, [
            _c("img", {
              attrs: {
                src: require("~/assets/gifs/" + i.cardType + "_nft.gif"),
                alt: "this slowpoke moves",
              },
            }),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-first-section-text" }, [
      _c("h1", [_vm._v("NFT Collection")]),
      _vm._v(" "),
      _c("h3", [_vm._v("Unique Design. Limited Supply. Lifetime Rewards.")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "mr-4",
        attrs: { href: "/NFT_WHITEPAPER_23_MAY_2023.pdf", target: "_blank" },
      },
      [
        _c("button", { staticClass: "pink-default-button" }, [
          _c("p", { staticClass: "m0" }, [_vm._v("Whitepaper")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-image-first-section" }, [
      _c("img", {
        attrs: { src: require("assets/dh-nft.png"), alt: "nft-imagem" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }