var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "form-section" } },
    [
      _c(
        "v-stepper",
        {
          staticClass: "custom-card",
          model: {
            value: _vm.step,
            callback: function ($$v) {
              _vm.step = $$v
            },
            expression: "step",
          },
        },
        [
          _c(
            "v-stepper-header",
            [
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.step > 1, step: "1" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("form_step_1.title_step")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.step > 2, step: "2" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("form_step_2.title_step")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-stepper-step", { attrs: { step: "3" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("form_step_3.title_step")) +
                    "\n      "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper-items",
            [
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c("Step1", {
                    attrs: { chieldSelectionOption: _vm.propsSelectionOption },
                    on: { goStep2Emit: _vm.goStep2 },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c("Step2", {
                    attrs: { step: _vm.step },
                    on: { goStep1Emit: _vm.goStep1, goStep3Emit: _vm.goStep3 },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c("Step3", {
                    attrs: { confirmUpdatedInfoProps: _vm.confirmUpdatedInfo },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }