<template>
  <div class="body-card-container" v-if="orferInfo.previewItens !== undefined && orferInfo.previewItens !== null">
      <h3>YOUR ORDER HAS BEEN SUBMITTED</h3>
      <h3 style="">{{ orferInfo.previewItens.cardTypeTitle }}</h3>

      <p>Our Theter (USDT) Solana address to send payments is the following:</p>

      <p class="address-bold"> 8uB3nBnnpJ6ZFjM3fyMbXPvzv3JtEHDZqVodK4Qi2cZZ </p>


      <div class="container-qrcode">
        <img src="~/assets/new_qr_code.png" alt="">
        <!-- <img src="~/assets/QR_wallet.png" alt=""> -->
      </div>

      <p class="order-amount order-amount-warning"> USE ONLY THE SOLANA NETWORK TO MAKE THE USDT DEPOSIT </p>

      <div v-if="orferInfo.previewItens !== undefined ">

        <p class="order-amount"> Subtotal: {{ orferInfo.previewItens.subTotal }} </p>
        <p class="order-amount" v-if="orferInfo.previewItens.discount !== undefined && orferInfo.previewItens.discount !== '' "> Discount: {{ orferInfo.previewItens.discount }} </p>
        <p class="order-amount"> Fee: {{ orferInfo.previewItens.fee }} </p>
        <p class="order-amount order-amount-total"> Amount of USDT to deposit: {{ orferInfo.previewItens.total }} </p>

      </div>
      <p>Ensure that this is the same address you receive in your inbox.</p>

      <div class="container-important-message">
        <h3>Important:</h3>
        <p>- Make sure you received a copy of your order in your inbox with this Solana payment address.</p>
        <p>- Once you verify your order, send the requested amount. You will receive a second email confirming your payment.</p>
        <p>- Please account for any fees your wallet might charge you, for us to receive the full amount.</p>
      </div>
      <!-- <p>Please send the requested Amount. Once you send your payment Please send an Email to <a href="mailto:nft@digitalhorizon.sv?subject=NFT" target="_blank">nft@digitalhorizon.sv</a> to complete and delivered your Order.</p> -->


      <div class="container-watch-tutorial">
        <p> You can get live support via </p>

        <div>
          <div>
            <v-icon x-large> mdi-whatsapp </v-icon>
          </div>
          <a href="https://wa.me/message/7SRJZ73GKEFZJ1" target="_blank">WhatsApp Business</a>
        </div>

      </div>

      <div class="wacth-tutorial-container">
        <a href="mailto:nft@digitalhorizon.sv?subject=NFT" target="_blank">nft@digitalhorizon.sv</a>
        <!-- <a href="/DH_NFTS_Whitepaper.mp4" target="_blank">watch tutorial</a> -->
      </div>
  </div>
</template>

<script>
export default {
  props:['confirmUpdatedInfoProps'],
  data: () => ({
    orferInfo: {
      previewItens: null,

    }
  }),
  mounted() {
    this.getOrderInfo()
  },
  methods: {
    getOrderInfo() {
      const savedOrderInfo = localStorage.getItem('step1-nft-form')
      if (savedOrderInfo !== null) {
        this.orferInfo = JSON.parse(savedOrderInfo)
      }
    }
  },
  watch: {
    confirmUpdatedInfoProps() {
      this.getOrderInfo()
      // console.info('this onde here ======> ', this.confirmUpdatedInfoProps)
    }
  }
}
</script>

<style lang="scss">
.container-qrcode {
  max-width: 80%;
  display: flex;
  margin: 0 auto;

  img {
    margin: 55px auto;
    width: 50%;
  }

  @media screen and (max-width: 800px) {
    img {
      width: 100%;
    }
  }
}

.address-bold {
  font-weight: 700;
}

.body-card-container {
  h3 {
    font-size: 1.5rem;
    display: block;
    margin: 20px 0 50px 0;
  }

  p {
    font-size: 1.5rem;
    margin: 20px 0 20px 0;
  }
}

.container-watch-tutorial {
  display: block;
  text-align: center;
  margin-top: 80px !important;

  a {
    margin: 20px auto;
    color: #fff;
  }
}

.order-amount {
  text-align: center;

  span {
    font-weight: 600;
  }
}

.order-amount-warning {
  margin-top: -30px !important;
  color: #ad70ff;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 55px !important;
}

.order-amount-total {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 70px !important;
}

.wacth-tutorial-container {
  text-align: center;
  color: #fff;
  margin-top: 40px;

  a {
    color: #fff;
  }
}


.container-important-message {
  margin-top: 40px;

  p {
    font-size: 1.2rem;
    margin: 10px 0;
  }

  h3 {
    margin: 20px 0;
  }
}

</style>
