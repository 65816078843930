<template>
    <div>
        <h1 class="title-card-preview">{{ savedAmountsInfos.cardTypeTitle }}</h1>
        <p>Please review the information carefully. You will receive an email confirming your order, so make sure the information provided is correct.</p>
        <div>
            <!-- <p> <strong>Quantity:</strong> {{ savedAmountsInfos.quantity }} </p> -->
            <p> <strong>Quantity:</strong> 1 </p>
            <p> <strong>Subtotal:</strong> {{ savedAmountsInfos.subTotal }} </p>
            <p> <strong>Fee:</strong> {{ savedAmountsInfos.fee }} </p>
            <p> <strong>Total:</strong> {{ savedAmountsInfos.total }} </p>
        </div>

        <div>
            <p> <strong>Name:</strong> {{ step1InfoPreview.name }} </p>
            <p> <strong>Email:</strong> {{ step1InfoPreview.email }} </p>
            <p> <strong>My wallet:</strong> {{ step1InfoPreview.walletAddress }} </p>
        </div>

        <div align="center" class="container-buttons-step2">
          <v-btn
            class="previus-button-step2"
            @click="returnStep"
            color="primary"
          >
            PREVIOUS
          </v-btn>

          <v-btn
            @click="submitAllInfo()"
            text
            class="continue-button-step2"
          >
            SUBMIT
          </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    props: ['step'],
    data: () => ({
        savedAmountsInfos: {
            discount: "",
            cardTypeTitle: "",
            fee: "",
            id: 0,
            option: "",
            subTotal: "",
            total: ""
        },
        step1InfoPreview: {
            quantity: '',
            name: '',
            email: '',
            walletAddress: '',
        },
    }),
    methods: {
      returnStep() {
        this.$emit('goStep1Emit')
      },
      checkSavedItens() {
        const savedNftForm = localStorage.getItem('step1-nft-form')
        const savedItens = JSON.parse(savedNftForm)
        if (savedItens) {
          this.savedAmountsInfos = savedItens.previewItens
          this.step1InfoPreview = savedItens.step1
        }
        // this.savedInfo = savedItens
      },
      submitAllInfo() {
        try {
          const bodySendEmail = {
            cardTypeTitle: this.savedAmountsInfos.cardTypeTitle,
            subTotal: this.savedAmountsInfos.subTotal,
            fee: this.savedAmountsInfos.fee,
            total: this.savedAmountsInfos.total,
            name: this.step1InfoPreview.name,
            email: this.step1InfoPreview.email,
            walletAddress: this.step1InfoPreview.walletAddress
          }

          const updateLocalStorage = {
            previewItens: this.savedAmountsInfos,
            step1: this.step1InfoPreview
          }

          localStorage.setItem('step1-nft-form', JSON.stringify(updateLocalStorage))

          this.$axios.post('/api/send/email', bodySendEmail)

          this.$emit('goStep3Emit', updateLocalStorage)
        } catch (e) {
          console.log(e)
        }
      }
    },
    created() {},
    mounted() {
      this.checkSavedItens()
    },
    watch: {
      step: {
        handler() {
          this.checkSavedItens()
        },
        deep: true,
      }
    }
}
</script>

<style>
.container-buttons-step2 {
  justify-content: space-around;
  display: flex;
  margin: 50px 0 20px 0;
}

.previus-button-step2 {
  width: 25%;
}

.continue-button-step2 {
  background-color: #3ed18f !important;
  width: 25%;
}

.title-card-preview {
  margin: 20px 0;
}
</style>
