<template>
    <div>
        <v-form>
            <v-container>
                <v-row class="d-block">
                        <!-- cols="12"
                        sm="6" -->
                  <v-col
                      class="d-block"
                  >
                    <v-select
                      :items="items"
                      v-model="step1.quantity"
                      :hint="`${items.option}`"
                      label="SELECT UNIT QUANTITY"
                      outlined
                      :error="step1Error.quantityError"
                      item-value="id"
                      item-text="option"
                    ></v-select>

                    <div>
                      <!-- {{ preview }} -->
                      <p>SUBTOTAL: {{preview.subTotal}}</p>
                      <p v-if="preview.discount !== '' ">DISCOUNT: {{preview.discount}}</p>
                      <p>FEE: {{preview.fee}}</p>
                      <p>TOTAL: {{preview.total}}</p>
                    </div>
                  </v-col>

                      <!-- cols="12"
                      md="4 " -->
                  <v-col>
                      <v-text-field
                          v-model="step1.name"
                          label="FULL NAME (OPTIONAL)"
                          required
                          outlined
                          :error="step1Error.nameError"
                      ></v-text-field>
                  </v-col>

                      <!-- cols="12"
                      md="4" -->
                  <v-col>
                      <v-text-field
                      autocapitalize="none"
                      v-model="step1.email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                      outlined
                      :error="step1Error.emailError"
                      ></v-text-field>
                      <p>A valid and secure email address is required.</p>
                  </v-col>

                      <!-- cols="12"
                      md="4" -->
                  <v-col
                  >
                      <v-text-field
                      v-model="step1.walletAddress"
                      label="SOLANA ADDRESS"
                      required
                      outlined
                      :error="step1Error.walletAddressError"
                      ></v-text-field>

                  </v-col>

                  <p>Input your Solana Address, used to make payments and receive your NFT. <span style="font-weight: 700">Please use ONLY Exodus or Phantom wallets.</span></p>
                  <p class="strong-warning">You must use a
                    <a href="https://www.youtube.com/watch?v=vt4qnmV_PTI&t=8s" target="_blank">Exodus</a> or
                    <a href="https://www.youtube.com/watch?v=zI-Xd9vVnwY&t=358s" target="_blank">Phantom</a> wallet.
                  </p>
                  <recaptcha class="recaptcha"/>
                  <p class="warning-message" v-if="validRecaptch == 'not-valid'">Necessário validar captcha!</p>

                </v-row>
            </v-container>
        </v-form>

        <div align="center">
          <v-btn
            class="submit-button"
            color="primary"
            @click="onSubmit()"
            large
          >
            NEXT
          </v-btn>
        </div>

        <!-- <button @click="onSubmit()">try captch</button> -->
    </div>
</template>

<script>
  export default {
    props:['chieldSelectionOption'],
    components: {},
    data: () => ({
        recaptchaToken: "",
        validRecaptch: "",
        preview: {
          subTotal: '',
          fee: '',
          discount: '',
          total: '',
        },
        step1: {
            quantity: '',
            email: '',
            name: '',
            walletAddress: '',
        },
        step1Error: {
          quantityError: false,
          nameError: false,
          emailError: false,
          walletAddressError: false,
        },
        valid: false,
        firstname: '',
        lastname: '',
        nameRules: [
            value => !!value || 'Name is required',
            value => (value && value.length < 41) || 'Must be a valid name',
        ],
        emailRules: [
            v => !!v || 'Email is required',
            v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
        items: [
          {
            id: 1,
            cardTypeTitle: 'Blue Single NFT',
            option: 'Blue Single NFT - $2,500',
            quantity: '1',
            subTotal: '2500 USDT',
            fee: '100 USDT',
            discount: '',
            total: '2600 USDT',
          },
          {
            id: 2,
            cardTypeTitle: 'Silver Compound NFT',
            option: 'Silver Compound NFT - $25,000',
            quantity: '10',
            subTotal: '25,000 USDT',
            fee: '400 USDT',
            discount: '3%',
            total: '24,650 USDT',
          },
          {
            id: 3,
            cardTypeTitle: 'Gold Master NFT',
            quantity: '100',
            option: 'Gold Master NFT - $250,000',
            subTotal: '250,000 USDT',
            fee: '600 USDT',
            discount: '5%',
            total: '238,100 USDT',
          }
        ],
    }),
    mounted() {
        const savedUserInfo = JSON.parse(localStorage.getItem('savedUserInfo-nft-flow'));

        if (savedUserInfo) {
            if (
                savedUserInfo.quantity ||
                savedUserInfo.email ||
                savedUserInfo.name ||
                savedUserInfo.walletAddress
            ) {
              this.step1 = {
                quantity: savedUserInfo.quantity,
                email: savedUserInfo.email,
                name: savedUserInfo.name,
                walletAddress: savedUserInfo.walletAddress,
              }
            }
        }
    },
    methods: {
        checkForm() {
            if (
                this.step1.quantity &&
                this.step1.email &&
                this.step1.name &&
                this.step1.walletAddress
            ) {
                this.valid = true;
            }
        },

        async validateFormStep1() {
          if(this.step1.quantity === ''){
              this.step1Error.quantityError = true
          }
          if(this.step1.name === ''){
              this.step1Error.nameError = true
          }
          if(this.step1.email === ''){
              this.step1Error.emailError = true
          }
          // if(this.step1.walletAddress === ''){
          //     this.step1Error.walletAddressError = true
          // }
        },

        async onSubmit() {
          try {
            if(
              this.step1.quantity === '' ||
              this.step1.name === '' ||
              this.step1.email === '' ||
              this.step1.walletAddress === ''
            ){
                this.step1Error.quantityError = true
                this.step1Error.nameError = true
                this.step1Error.emailError = true
                this.step1Error.walletAddressError = true
                throw new Error('missing fields')
            }

            const token = await this.$recaptcha.getResponse()
            this.validRecaptch = ""

            const savedSelectedInfo = {
              step1: this.step1,
              previewItens: this.preview,
            }

            localStorage.setItem('ReCaptchaToken', token)
            localStorage.setItem('step1-nft-form', JSON.stringify(savedSelectedInfo))

            this.$emit('goStep2Emit')

            await this.$recaptcha.reset()
          } catch (error) {
            this.validRecaptch = "not-valid"
            console.log('Login error:', error)
          }
        },

        cleanErrorFields() {
          if(this.step1.quantity !== '') {
            this.step1Error.quantityError = false
          }
          if(this.step1.name !== '') {
            this.step1Error.nameError = false
          }
          if(this.step1.email !== '') {
            this.step1Error.emailError = false
          }
          if(this.step1.walletAddress !== '') {
            this.step1Error.walletAddressError = false
          }
        },

        setPreviewInfo() {
          const filterForItens = this.items.filter(item => item.id === this.step1.quantity)
          this.preview = filterForItens[0]
        },

        checkChosenOption() {
          this.step1.quantity = this.chieldSelectionOption
        },

    },
    watch: {
        step1: {
            handler() {
                this.cleanErrorFields();
                this.checkForm();
                this.setPreviewInfo();
                localStorage.setItem('savedUserInfo-nft-flow', JSON.stringify(this.step1));
            },
            deep: true,
        },
        chieldSelectionOption: {
            handler() {
              // console.info('chieldSelectionOption into step1 ===> ', this.chieldSelectionOption)
              this.checkChosenOption();
            },
            deep: true,
        }
    }
  }
</script>

<style lang="scss">
.recaptcha {
  div {
    margin: 10px auto;
  }
}

.warning-message {
  color: red;
  text-align: center;
}

.submit-button {
  max-width: 70%;
  width: 40%;
  margin: 60px auto 30px auto;
  font-size: 1.2rem !important;
}

.strong-warning {
  font-weight: 700;
  a {
    color: #fff !important;
  }
}
</style>
