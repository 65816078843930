<template>
  <div class="container-foter-text">
    <a href="https://www.digitalhorizon.sv/_files/ugd/6a5e9d_ddce6fbcafa74869bca847b4d66e94b4.pdf" target="_blank">Terms and Conditions - Privacy Policy</a>

    <div class="middle-block-footer-email">
      <a href="mailto:support@digitalhorizon.sv?subject=Support" target="_self">support@digitalhorizon.sv </a>
      <span class="separator-footer-emails"> | </span>
      <a href="mailto:nft@digitalhorizon.sv?subject=NFT" target="_self"> nft@digitalhorizon.sv</a>
    </div>

    <div>
      <p>© {{ myYear }} Digital Horizon Blockchain Technology - Sociedad Anonima de Capital Variable<br>NRC 309482-6 - Calle Cuscatlan 83 Av. Sur. Col. Escalon, #375, San Salvador</p>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    myYear: ""
  }),

  mounted() {
    this.updateYear();
  },

  methods: {
    updateYear () {
      const year = new Date();
      this.myYear = year.getFullYear();
      return;
    }
  }
}
</script>

<style lang="scss">
.container-foter-text {
  text-align: center;

  a {
    text-decoration: none;
    color: #fff;
    font-size: 0.8rem;
  }

  p {
    font-size: 0.8rem;
  }
}


.middle-block-footer-email {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;

  a {
    text-decoration: revert !important;
  }
}


.separator-footer-emails {
  margin: 0 10px;
}
</style>
