var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "d-block" },
                [
                  _c(
                    "v-col",
                    { staticClass: "d-block" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.items,
                          hint: "" + _vm.items.option,
                          label: "SELECT UNIT QUANTITY",
                          outlined: "",
                          error: _vm.step1Error.quantityError,
                          "item-value": "id",
                          "item-text": "option",
                        },
                        model: {
                          value: _vm.step1.quantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.step1, "quantity", $$v)
                          },
                          expression: "step1.quantity",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", [
                        _c("p", [
                          _vm._v("SUBTOTAL: " + _vm._s(_vm.preview.subTotal)),
                        ]),
                        _vm._v(" "),
                        _vm.preview.discount !== ""
                          ? _c("p", [
                              _vm._v(
                                "DISCOUNT: " + _vm._s(_vm.preview.discount)
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", [_vm._v("FEE: " + _vm._s(_vm.preview.fee))]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("TOTAL: " + _vm._s(_vm.preview.total)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "FULL NAME (OPTIONAL)",
                          required: "",
                          outlined: "",
                          error: _vm.step1Error.nameError,
                        },
                        model: {
                          value: _vm.step1.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.step1, "name", $$v)
                          },
                          expression: "step1.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          autocapitalize: "none",
                          rules: _vm.emailRules,
                          label: "E-mail",
                          required: "",
                          outlined: "",
                          error: _vm.step1Error.emailError,
                        },
                        model: {
                          value: _vm.step1.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.step1, "email", $$v)
                          },
                          expression: "step1.email",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("A valid and secure email address is required."),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "SOLANA ADDRESS",
                          required: "",
                          outlined: "",
                          error: _vm.step1Error.walletAddressError,
                        },
                        model: {
                          value: _vm.step1.walletAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.step1, "walletAddress", $$v)
                          },
                          expression: "step1.walletAddress",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Input your Solana Address, used to make payments and receive your NFT. "
                    ),
                    _c("span", { staticStyle: { "font-weight": "700" } }, [
                      _vm._v("Please use ONLY Exodus or Phantom wallets."),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "strong-warning" }, [
                    _vm._v("You must use a\n                "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.youtube.com/watch?v=vt4qnmV_PTI&t=8s",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Exodus")]
                    ),
                    _vm._v(" or\n                "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.youtube.com/watch?v=zI-Xd9vVnwY&t=358s",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Phantom")]
                    ),
                    _vm._v(" wallet.\n              "),
                  ]),
                  _vm._v(" "),
                  _c("recaptcha", { staticClass: "recaptcha" }),
                  _vm._v(" "),
                  _vm.validRecaptch == "not-valid"
                    ? _c("p", { staticClass: "warning-message" }, [
                        _vm._v("Necessário validar captcha!"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { align: "center" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "submit-button",
              attrs: { color: "primary", large: "" },
              on: {
                click: function ($event) {
                  return _vm.onSubmit()
                },
              },
            },
            [_vm._v("\n        NEXT\n      ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }