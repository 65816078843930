var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", { staticClass: "loading-page" }, [
        _c("p", [_vm._v("Loading...")]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }