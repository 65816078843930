<template>
  <div id="form-section">
    <v-stepper class="custom-card" v-model="step">
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          {{ $t('form_step_1.title_step') }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 2"
          step="2"
        >
          {{ $t('form_step_2.title_step') }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          {{ $t('form_step_3.title_step') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <Step1
            :chieldSelectionOption="propsSelectionOption"
            @goStep2Emit="goStep2"
          />
        </v-stepper-content>

        <v-stepper-content step="2">
          <Step2
            @goStep1Emit="goStep1"
            @goStep3Emit="goStep3"
            :step="step"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <Step3 :confirmUpdatedInfoProps="confirmUpdatedInfo"/>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Step1 from './Step1.vue'
  import Step2 from './Step2.vue'
  import Step3 from './Step3.vue'

  export default Vue.extend({
    props: ['propsSelectionOption'],
    name: 'HelloWorld',
    components: {
      Step1,
      Step2,
      Step3,
    },
    data: () => ({
      step: 1,
      confirmUpdatedInfo: [],
    }),
    methods: {
      goStep1() {
        // console.info('=== goStep1 ===')
        this.step = 1
      },
      goStep2() {
        // console.info('=== goStep2 ===')
        this.step = 2
      },
      goStep3(confirmUpdatedInfo) {
        // console.info('=== goStep3 ===')
        this.confirmUpdatedInfo = confirmUpdatedInfo
        this.step = 3
      },
    },
    watch: {
      propsSelectionOption: {
        handler() {},
        immediate: true,
      }
    }
  })
</script>

<style lang="scss">
.custom-card {
  width: 50%;
  margin: 10vw auto 50px auto;

  @media screen and (max-width: 1290px) {
    width: 65%;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
}
</style>
