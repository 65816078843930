var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-foter-text" }, [
    _c(
      "a",
      {
        attrs: {
          href: "https://www.digitalhorizon.sv/_files/ugd/6a5e9d_ddce6fbcafa74869bca847b4d66e94b4.pdf",
          target: "_blank",
        },
      },
      [_vm._v("Terms and Conditions - Privacy Policy")]
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", [
      _c("p", [
        _vm._v(
          "© " +
            _vm._s(_vm.myYear) +
            " Digital Horizon Blockchain Technology - Sociedad Anonima de Capital Variable"
        ),
        _c("br"),
        _vm._v(
          "NRC 309482-6 - Calle Cuscatlan 83 Av. Sur. Col. Escalon, #375, San Salvador"
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "middle-block-footer-email" }, [
      _c(
        "a",
        {
          attrs: {
            href: "mailto:support@digitalhorizon.sv?subject=Support",
            target: "_self",
          },
        },
        [_vm._v("support@digitalhorizon.sv ")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "separator-footer-emails" }, [_vm._v(" | ")]),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "mailto:nft@digitalhorizon.sv?subject=NFT",
            target: "_self",
          },
        },
        [_vm._v(" nft@digitalhorizon.sv")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }