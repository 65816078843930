import { render, staticRenderFns } from "./Step2.vue?vue&type=template&id=52e29b9b&"
import script from "./Step2.vue?vue&type=script&lang=js&"
export * from "./Step2.vue?vue&type=script&lang=js&"
import style0 from "./Step2.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/dh/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52e29b9b')) {
      api.createRecord('52e29b9b', component.options)
    } else {
      api.reload('52e29b9b', component.options)
    }
    module.hot.accept("./Step2.vue?vue&type=template&id=52e29b9b&", function () {
      api.rerender('52e29b9b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/Step2.vue"
export default component.exports