var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.orferInfo.previewItens !== undefined &&
    _vm.orferInfo.previewItens !== null
    ? _c("div", { staticClass: "body-card-container" }, [
        _c("h3", [_vm._v("YOUR ORDER HAS BEEN SUBMITTED")]),
        _vm._v(" "),
        _c("h3", {}, [
          _vm._v(_vm._s(_vm.orferInfo.previewItens.cardTypeTitle)),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Our Theter (USDT) Solana address to send payments is the following:"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "address-bold" }, [
          _vm._v(" 8uB3nBnnpJ6ZFjM3fyMbXPvzv3JtEHDZqVodK4Qi2cZZ "),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("p", { staticClass: "order-amount order-amount-warning" }, [
          _vm._v(" USE ONLY THE SOLANA NETWORK TO MAKE THE USDT DEPOSIT "),
        ]),
        _vm._v(" "),
        _vm.orferInfo.previewItens !== undefined
          ? _c("div", [
              _c("p", { staticClass: "order-amount" }, [
                _vm._v(
                  " Subtotal: " +
                    _vm._s(_vm.orferInfo.previewItens.subTotal) +
                    " "
                ),
              ]),
              _vm._v(" "),
              _vm.orferInfo.previewItens.discount !== undefined &&
              _vm.orferInfo.previewItens.discount !== ""
                ? _c("p", { staticClass: "order-amount" }, [
                    _vm._v(
                      " Discount: " +
                        _vm._s(_vm.orferInfo.previewItens.discount) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", { staticClass: "order-amount" }, [
                _vm._v(" Fee: " + _vm._s(_vm.orferInfo.previewItens.fee) + " "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "order-amount order-amount-total" }, [
                _vm._v(
                  " Amount of USDT to deposit: " +
                    _vm._s(_vm.orferInfo.previewItens.total) +
                    " "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Ensure that this is the same address you receive in your inbox."
          ),
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "container-watch-tutorial" }, [
          _c("p", [_vm._v(" You can get live support via ")]),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              [
                _c("v-icon", { attrs: { "x-large": "" } }, [
                  _vm._v(" mdi-whatsapp "),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://wa.me/message/7SRJZ73GKEFZJ1",
                  target: "_blank",
                },
              },
              [_vm._v("WhatsApp Business")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(2),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-qrcode" }, [
      _c("img", { attrs: { src: require("assets/new_qr_code.png"), alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-important-message" }, [
      _c("h3", [_vm._v("Important:")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "- Make sure you received a copy of your order in your inbox with this Solana payment address."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "- Once you verify your order, send the requested amount. You will receive a second email confirming your payment."
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "- Please account for any fees your wallet might charge you, for us to receive the full amount."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wacth-tutorial-container" }, [
      _c(
        "a",
        {
          attrs: {
            href: "mailto:nft@digitalhorizon.sv?subject=NFT",
            target: "_blank",
          },
        },
        [_vm._v("nft@digitalhorizon.sv")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }