export default {
  header: {
    link_1: 'About',
    link_2: 'Our Products',
    link_3: 'Benefits',
    link_4: 'Future Projects',
    link_5: 'Contact',
    button_link_name: 'NFT',
  },

  form_step_1: {
    title_step: 'Place Order'
  },

  form_step_2: {
    title_step: 'Review'
  },

  form_step_3: {
    title_step: 'Instructions'
  }
}
