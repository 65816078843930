<template>
  <div class="bg-black">

    <div class="container-header-itens">

      <div class="container-menu">
        <svg @click="openMobileMenu" fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"/></svg>
      </div>

      <div class="header-first-block">
        <img src="~/assets/dh_white.png" alt="digital horizon logo" class="img-header">
      </div>

      <div class="header-midle-block">
        <a href="https://www.digitalhorizon.sv/"> {{ $t('header.link_1') }} </a>
        <a href="https://www.digitalhorizon.sv/#comp-kr24089t"> {{ $t('header.link_2') }} </a>
        <a href="https://www.digitalhorizon.sv/#comp-kr24egkd"> {{ $t('header.link_3') }} </a>
        <a href="https://www.digitalhorizon.sv/#comp-kr24egkd"> {{ $t('header.link_4') }} </a>
        <a href="https://www.digitalhorizon.sv/#comp-kr24egja"> {{ $t('header.link_5') }} </a>
        <button class="pink-default-button">{{ $t('header.button_link_name') }}</button>
      </div>

      <!-- <div class="header-end-block">
        <nuxt-link class="d-flex" :to="switchLocalePath(locale.code)" v-for="(locale, index) in availableLocales" :key="index">
          <p class="change-lang-code">{{ locale.code }}</p> <span v-if="index < 2">|</span>
        </nuxt-link>
      </div> -->
    </div>

    <div v-if="mobileMenuState" class="container-mobile-menu">
      <div class="container-close">
        <p @click="colseMobileModal">x</p>
      </div>

      <div class="container-body-mobile-menu">
          <a href="https://www.digitalhorizon.sv/"> {{ $t('header.link_1') }} </a>
          <a href="https://www.digitalhorizon.sv/#comp-kr24089t"> {{ $t('header.link_2') }} </a>
          <a href="https://www.digitalhorizon.sv/#comp-kr24egkd"> {{ $t('header.link_3') }} </a>
          <a href="https://www.digitalhorizon.sv/#comp-kr24egkd"> {{ $t('header.link_4') }} </a>
          <a href="https://www.digitalhorizon.sv/#comp-kr24egja"> {{ $t('header.link_5') }} </a>
          <button class="pink-default-button">{{ $t('header.button_link_name') }}</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data: () => ({
    mobileMenuState: false,
  }),
  computed: {
    availableLocales () {
      return this.$i18n.locales
    },

    currentSelectLocale () {
      const currentSelect3Locale = this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
      return currentSelect3Locale[0].code
    },
  },

  methods: {
    colseMobileModal() {
      this.mobileMenuState = false
    },
    openMobileMenu() {
      this.mobileMenuState = true
    }
  }
}
</script>

<style lang="scss">
.container-menu {
  display: none;
  width: 50px;

  svg {
    fill: #fff;
  }

  @media screen and (max-width: 800px) {
    display: block;
  }
}

.container-header-itens {
  display: flex;
  /* background: #1e1e1e; */
  padding: 15px;
  max-width: 95%;
  width: 52%;
  margin: 0 auto;

  a {
    text-decoration: none;
    color: #fff;
  }

  @media screen and (max-width: 1590px) {
    width: 65%;
  }

  @media screen and (max-width: 1290px) {
    width: 90%;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.img-header {
  width: 100%;
}

.change-lang-code {
  /* margin: 0 !important; */
  text-transform: uppercase;
  margin: 0 5px !important;
}

.header-first-block {
  display: block;
  max-width: 100%;
  width: 20%;

  @media screen and (max-width: 800px) {
    width: 100%;
    display: flex;

    img {
      width: 50%;
      margin: 0 auto;
    }
  }
}

.header-button {
  display: block;
  padding: 8px 30px;
  background: #004eff;
  color: #fff;
  border: none;
  border-radius: 100px;
  box-shadow: 5px 3px 20px rgba(255, 255, 255, 0.533);
}

.header-midle-block {
  display: flex;
  margin: 0 10px 0 auto;
  align-items: center;
  justify-content: space-around;
  width: 60%;

  a {
    color: #fff !important;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
}

.header-end-block {
  display: flex;
  max-width: 100%;
  width: 8%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    display: none;
  }
}

.container-mobile-menu {
  max-width: 100%;
  width: 100%;
  height: 100vh;
  background: linear-gradient(90deg, rgba(11,17,88,1) 0%, rgba(5,7,34,1) 100%);
  position: fixed;
  z-index: 9999999;
  top: 0;
}

.container-close {
  max-width: 100%;
  width: 100%;

  p {
    font-size: 3rem;
    margin-left: 50px;
  }
}

.container-body-mobile-menu {
  max-width: 100%;
  width: 100%;
  text-align: center;

  a {
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 2.6rem;
    margin: 40px auto;
  }

  button {
    font-size: 2.6rem;
  }
}

</style>
